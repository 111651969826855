import { Center, Container, Text } from "@chakra-ui/react";

function App() {
  return (
    <Container height='100vh' width='full'>
      {
        /*
          <Center height='full'>
            <Text>Under Construction</Text>
          </Center>
        */
      }
    </Container>
  );
}

export default App;
